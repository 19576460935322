































































import { Observer } from 'mobx-vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { notificationStore } from '@/stores/notification-store'
@Observer
@Component({
  components: {
    'notification-item': () => import('@/components/notification/notification-item.vue'),
  },
})
export default class Notifications extends Vue {
  notificationStore = notificationStore
  focusId = ''

  @Watch('$route', { immediate: true }) tabChange(val) {
    const hash = this.$router.currentRoute.hash.replaceAll('#', '')
    this.focusId = hash
    if (hash) {
      setTimeout(() => {
        // const element = document.getElementById(`data-${hash}`)
        document
          .getElementById(`data-${hash}`)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      }, 300)
    }
  }
}
